@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
// $break-small: 519px
$break-small: 575px;
$break-medium: 767px;
$break-large: 991px;
$break-extra-large: 1199px;
$primary-color: #07294a;
$secondary-color: #002e5b;
$third-color: #ffc600;
$text-color: #1a1a1c;
$anim-duration: 7s;

html {
	scroll-behavior: smooth; }

body {
	font-family: 'Open Sans', sans-serif; }

.header {
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		height: 340px;
		background-color: $primary-color; } }

.nav-container, .banner, .home_about {
	max-width: 1440px;
	margin: 0 auto; }

.nav-container {
	background-color: $primary-color !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	@media screen and ( max-width: 1440px ) {
		padding-left: 1rem !important;
		padding-right: 1rem !important; }
	.navbar {
		background-color: $primary-color !important;
		.navbar-toggler {
			color: #ffffff;
			border-color: #fff;
			outline: none; }
		.navbar-nav {
			.nav-link {
				color: #fff;
				&:hover {
					color: $third-color; } } } }
	a {
		color: #ffffff;
		text-transform: uppercase;
		letter-spacing: 3px;
		transition: all .1s linear;
		&.navbar-brand {
			&:hover {
				color: $third-color; } } }
	.banner-logo {
		height: 48px; } }


.banner {
	// background: url("images/banner_bg.png") no-repeat
	background: url("images/banner_bg2.jpg") no-repeat;
	background-color:  $secondary-color;
	background-position: 50% 10%;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		animation: bg-fade 2s $anim-duration+$anim-duration+$anim-duration+1s linear both; }
	.row {
		position: relative;
		z-index: 3; }
	.social_links {
		position: absolute;
		z-index: 4;
		top: 3%;
		right: 2%;
		a {
			color: #fff;
			font-size: 20px;
			padding: 10px;
			&:hover {
				color: $third-color; } } }
	// .banner-logo
	// 	width: 150px
	// 	margin: 90px auto 30px 90px
	// 	@media screen and (max-width: $break-large)
	// 		margin: 50px auto 20px 50px
	// 	@media screen and (max-width: $break-medium)
	// 		margin: 30px auto 20px 20px
	.banner-heading-1, .banner-heading-2, .banner-heading-3 {
		margin-left: 90px;
		// font-family: 'Open Sans', sans-serif
		font-family: 'Montserrat', sans-serif;
		text-transform: uppercase;
		letter-spacing: 6px;
		line-height: 1;
		@media screen and (max-width: $break-large) {
			margin-left: 50px; }
		@media screen and (max-width: $break-medium) {
			margin-left: 20px; } }
	.banner-heading-1 {
		font-size: 40px;
		color: #fff;
		margin-bottom: 0;
		@media screen and (max-width: $break-medium) {
			font-size: 28px; } }
	.banner-heading-2 {
		font-size: 66px;
		font-weight: 700;
		color: $third-color;
		@media screen and (max-width: $break-medium) {
			font-size: 46px; } }
	.banner-heading-3 {
		font-size: 34px;
		font-weight: 600;
		color: #fff;
		line-height: 1.2;
		letter-spacing: 2px;
		width: 90%;
		@media screen and (max-width: $break-extra-large) {
			font-size: 28px;
 }			// width: 85%
		@media screen and (max-width: $break-large) {
			font-size: 24px; }
		@media screen and (max-width: $break-medium) {
			font-size: 20px;
			width: 95%; }
		@media screen and (max-width: $break-small) {
			font-size: 16px; } }
	.banner__text-container {
		height: 200px;
		@media screen and (max-width: $break-extra-large) {
			height: 150px; }
		@media screen and (max-width: $break-medium) {
			height: 100px; }
		@media screen and (max-width: $break-small) {
			height: 50px; } }
	.banner__text-show--1, .banner__text-show--2, .banner__text-show--3, .banner__text-show--4 {
		position: absolute;
		top: 150px;
		left: 0;
		height: 100px;
		@media screen and (max-width: $break-large) {
			top: 100px; }
		@media screen and (max-width: $break-medium) {
			top: 80px; }
		@media screen and (max-width: $break-small) {
			top: 50px; } }
	.banner__text-show--1 {
		animation: banner_text $anim-duration 1s linear both; }
	.banner__text-show--2 {
		animation: banner_text $anim-duration $anim-duration+1s linear both; }
	.banner__text-show--3 {
		animation: banner_text $anim-duration $anim-duration+$anim-duration+1s linear both; }
	.banner__text-show--4 {
		animation: banner_text-main $anim-duration $anim-duration+$anim-duration+$anim-duration+1s linear both; }
	.banner-newsletter {
		margin-top: 310px;
		padding-right: 0 !important;
		padding-left: 0 !important;
		background-color: $third-color;
		@media screen and (max-width: $break-large) {
			margin-top: 200px; }
		.newsletter-message {
				font-family: 'Open Sans', sans-serif;
				text-align: center;
				margin-bottom: 10px;
				margin-top: -20px;
				padding: 0 1.6rem;
				font-weight: 700;
				&--error {
					color: #F00; }
				&--success {
					color: #070; } }
		.newsletter-message--loader {
			display: block;
			overflow: hidden;
			position: relative;
			width: 14px;
			margin: 0 auto;
			.newsletter-message--loader-hide {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 50px;
				height: 20px;
				background-color: $third-color;
				animation: loading 1.5s linear infinite;
				animation-timing-function: steps(4, end); } }
		.newsletter {
			flex-wrap: wrap;
			align-items: center;
			// background-color: $third-color
			font-family: 'Open Sans', sans-serif;
			min-height: 100px;
			padding: 1.6rem;
			column-gap: 2%;
			.n_form_item {
				height: 48px;
				@media screen and (max-width: $break-medium) {
					width: 100%;
					margin-top: 0.5rem; } }
			.n_form_input, .n_form_btn {
				font-size: 15px;
				border: none;
				letter-spacing: 2px;
				padding: 0 1.2rem;
				outline: none; }
			.n_form_input {
				flex-grow: 2;
				transition: all .1s linear;
				&[iserror="true"] {
					box-shadow: inset 0 0 0 2px #f00; } }
			.n_form_btn {
				flex-grow: 1;
				background-color: #1161af;
				color: #fff;
				transition: all .15s linear;
				&:hover {
					background-color:#d91f4d {} }
				&:active {
					background-color: #dae0e5;
					color: #000; } }
			.n_form_title {
				width: 220px;
				font-size: 20px;
				color: #212121;
				letter-spacing: 4px;
				text-transform: uppercase;
				@media screen and (max-width: 767px) {
					width: 100%;
					height: auto; } } } } }

@keyframes banner_text {
	0% {
		opacity: 0; }
	3% {
		opacity: 0; }
	15% {
		opacity: 1; }
	85% {
		opacity: 1; }
	97% {
		opacity: 0; }
	100% {
		opacity: 0; } }

@keyframes banner_text-main {
	0% {
		opacity: 0; }
	3% {
		opacity: 0; }
	15% {
		opacity: 1; }
	85% {
		opacity: 1; }
	97% {
		opacity: 1; }
	100% {
		opacity: 1; } }

@keyframes bg-fade {
	0% {
		background-color:  rgba(0, 0, 0, 0.7); }
	100% {
		background-color:  rgba(0, 0, 0, 0.3); } }

@keyframes loading {
	0% {
		left: 0; }
	100% {
		left: 150%; } }


section {
	.section_heading {
		// font-family: 'Open Sans', sans-serif
		font-family: 'Bebas Neue', sans-serif;
		text-transform: uppercase;
		color: #000000;
		font-size: 48px;
		letter-spacing: 3px;
		@media screen and (max-width: $break-medium) {
			font-size: 40px; }
		@media screen and (max-width: $break-small) {
			font-size: 32px; } }
	.heading-border {
		height: 1px;
		width: 40%;
		background-color: #dcdcdc;
		margin: 0 auto; }
	.section_text {
		text-align-last: center; }
	.section_text, .section_text_smaller {
		font-family: 'Open Sans', sans-serif;
		font-size: 18px;
		// font-weight: 300
		color: $text-color;
		@media screen and (max-width: $break-large) {
			font-size: 16px; } }
	.section_text_smaller {
		font-size: 16px; }
	.img-shadow {
		box-shadow: 0 0 8px rgba(0, 0, 0, .5); }
	.section_small_line {
		top: 6px;
		right: 104%;
		display: block;
		width: 44px;
		height: 4px;
		background-color: #bea67c; }
	.section_small_line_center {
		top: auto;
		bottom: 0;
		right: 50%;
		transform: translateX(50%); }
	&.home_about {
		.img-about_us {
			width: 100%; } }
	&.home_benefits {
		.benefits_title {
			text-transform: uppercase;
			font-family: 'Open Sans', sans-serif;
			font-size: 21px;
			line-height: 26px;
			color: #002e5b;
			font-weight: 400;
			min-height: 52px;
			@media screen and (max-width: $break-extra-large) {
				min-height: 78px; }
			@media screen and (max-width: $break-large) {
				min-height: 104px; }
			@media screen and (max-width: $break-medium) {
				min-height: auto; } }
		.benefits_text {
			font-family: 'Open Sans', sans-serif;
			color: $text-color;
			font-size: 16px;
			line-height: 25px; }
		.benefits_box-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.benefits_box {
				overflow: hidden;
				width: 260px;
				height: 260px;
				@media screen and (max-width: $break-extra-large) {
					width: 220px;
					height: 220px; }
				@media screen and (max-width: $break-large) {
					width: 160px;
					height: 160px; }
				@media screen and (max-width: $break-medium) {
					width: 240px;
					height: 150px; }
				@media screen and (max-width: $break-small) {
					width: 100%; }
				.bg {
					width: 100%;
					height: 100%;
					background-color: #d5b94e;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					&--1 {
						background-image: url("images/box/bw/box-img-1.jpg"); }
					&--2 {
						background-image: url("images/box/bw/box-img-2.jpg"); }
					&--3 {
						background-image: url("images/box/bw/box-img-3-1.jpg"); }
					&--4 {
						background-image: url("images/box/bw/box-img-4-1.jpg"); }
					&--5 {
						background-image: url("images/box/bw/box-img-5.jpg"); }
					&--6 {
						background-image: url("images/box/bw/box-img-6.jpg"); }
					&--7 {
						background-image: url("images/box/bw/box-img-7.jpg"); }
					&--8 {
						background-image: url("images/box/bw/box-img-8.jpg"); }
					.cover {
						width: 100%;
						height: 100%;
 }						// background-color: rgba(213, 186, 78, 0.3)
					.text-1, .text-2 {
						position: relative;
						z-index: 5;
						font-family: 'Open Sans', sans-serif;
						text-transform: uppercase;
						color: #fff;
						left: 14px;
						@media screen and (max-width: $break-large) {
							left: 5px; }
						@media screen and (max-width: $break-small) {
							left: 14px; } }
					.text-2 {
						font-size: 20px;
						font-weight: 700;
						top: 190px;
						line-height: 1.2;
						@media screen and (max-width: $break-extra-large) {
							font-size: 20px;
							top: 160px; }
						@media screen and (max-width: $break-large) {
							font-size: 15px;
							top: 118px; }
						@media screen and (max-width: $break-medium) {
							top: 112px; }
						@media screen and (max-width: $break-small) {
							font-size: 20px;
							top: 100px; } } }
				.shape {
					top: 0;
					left: 0;
					transform: rotate(-170deg) translate(9%, -93%);
					width: 360px;
					height: 200px;
					// background-color: rgba(191, 167, 69, 0.7)
					background-color: rgba(7, 41, 74, 0.7);
					@media screen and (max-width: $break-extra-large) {
						transform: rotate(-171deg) translate(9%, -77%); }
					@media screen and (max-width: $break-large) {
						transform: rotate(-171deg) translate(9%, -63%); }
					@media screen and (max-width: $break-medium) {
						transform: rotate(-171deg) translate(9%, -55%); }
					@media screen and (max-width: $break-small) {
						width: 600px;
						height: 200px;
						transform: rotate(-171deg) translate(4%, -45%); }
					@media screen and (max-width: 319px) {
						transform: rotate(-171deg) translate(4%, -59%); } } } } }
	&.home_quote {
		background: url("images/quote.png");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		color: #f9f9f9;
		.quote_sign {
			font-size: 60px;
			font-family: 'Passion One', cursive;
			transform: rotate(180deg); }
		.quote_text {
			font-family: 'Open Sans', sans-serif;
			font-size: 36px;
			font-weight: 600;
			text-transform: uppercase;
			line-height: 1.2;
			padding: 0 24%;
			@media screen and (max-width: $break-extra-large) {
				font-size: 30px;
				padding: 0 16%; }
			@media screen and (max-width: $break-large) {
				font-size: 26px;
				padding: 0 10%; }
			@media screen and (max-width: $break-medium) {
				font-size: 22px; }
			@media screen and (max-width: $break-small) {
				font-size: 20px; } }
		.quote_author {
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			text-transform: uppercase;
			&::after, &::before {
				position: absolute;
				content: "";
				height: 2px;
				width: 20px;
				background-color: #fff;
				top: calc( 50% - 2px ); }
			&::after {
				left: calc( 50% + 55px ); }
			&::before {
				right: calc( 50% + 55px ); } } }
	&.home_collab {
		overflow: hidden;
		.heading_extra_home_collab {
			top: 100px;
			left: 60%;
			@media screen and (max-width: $break-large) {
				display: none; } }
		.home_collab-img-container {
			.home_collab-img {
				width: 100%;
				height: 300px;
				background-position: center;
				background-size: cover;
				@media screen and (max-width: $break-small) {
					height: 200px; }
				&--1 {
					background-image: url("images/partner_1.jpg"); }
				&--2 {
					background-image: url("images/partner_3.jpg"); } } }
		.section_small_line {
			@media screen and (max-width: $break-small) {
				top: -6px;
				right: auto;
				left: 0; } } }
	&.home_creators {
		.section_text {
			width: 55%;
			margin: 0 auto;
			@media screen and (max-width: $break-large) {
				width: 80%; }
			@media screen and (max-width: $break-medium) {
				width: 100%; } }
		img {
			max-width: 200px;
			max-height: 100px;
			@media screen and (max-width: $break-medium) {
				max-width: 255px; } } } }

section {
	&.home_contact {
		background: url("images/contact_bg.jpg");
		background-position: 50%  50%;
		background-repeat: no-repeat;
		background-size: cover;
		.map_and_address {
			background-color: #fff;
			.map_container {
				@media screen and (max-width: $break-medium) {
					height: 250px; } }
			.contact_title {
				font-size: 20px;
				letter-spacing: 1px;
				line-height: 24px;
				color: #424242;
				font-weight: 600;
				font-family: 'Open Sans', sans-serif; }
			.contact_line {
				width: 60%;
				height: 1px;
				background-color: #eaeaeb; }
			.contact_text {
				font-size: 14px;
				color: #424242;
				font-family: 'Open Sans', sans-serif;
				a {
					color: #424242; } }
			.contact_icon {
				width: 20px;
				left: -24px; } }
		.contact-form {
			font-family: 'Open Sans', sans-serif;
			input, textarea {
				padding: 10px 15px;
				height: auto;
				font-size: 14px;
				&:focus {
					box-shadow: 0 0 0 0.2rem rgba(216,217,219,.5); } }
			textarea {
				min-height: 250px; }
			button {
				padding: 10px 15px;
				font-family: 'Open Sans', sans-serif;
				font-size: 12px;
				letter-spacing: 1px;
				font-weight: 700;
				background-color: $third-color;
				color: #fff;
				&:hover {
					background-color: #d91f4d; } }
			.success, .warning {
				display: inline-block;
				padding: 10px 15px;
				margin-top: 1px;
				font-family: 'Open Sans', sans-serif;
				font-size: 12px;
				letter-spacing: 1px;
				font-weight: 700;
				transition: all .3s linear;
				margin-right: 1.5rem;
				@media screen and (max-width: $break-medium) {
					margin-right: 0;
					margin-bottom: 1rem; } }
			.warning {
				background-color: #e62929;
				color: #fff; }
			.success {
				background-color: #6ad227; }
			.form-text {
				color: #fff; } } } }


footer {
	background-color: $secondary-color;
	.footer_text {
		color: #fff;
		font-family: 'Montserrat', sans-serif;
		.name {
			color: $third-color; } } }
